import {Credentials} from 'aws-sdk/clients/sts';
import {authTokenPayload} from '../../../app/AuthenticationProvider';
import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import config, {SystemAPIs} from '../../config';
import {extractStatusCode, handleError} from './handleError';

import {getForecastEndpoint, GetForecastParams} from './getForecastEndpoint';
import {getForecastMetadataEndpoint, GetForecastMetadataParams} from './getForecastMetadataEndpoint';
import {getForecastMetricEndpoint, GetForecastMetricParams} from './getForecastMetricEndpoint';
import {ForecastRecord} from '../models/forecast';
import {ForecastRecordWithValues} from '../models/getForecastResponse';
import {GetForecastMetadataResponse} from '../models/getForecastResponse';
import {GetForecastMetricResponse} from '../models/getForecastResponse';

import {getForecastGraphEndpoint, GetForecastGraphParams} from './getForecastGraphEndpoint';
import {ForecastGraph} from '../models/getForecastGraphResponse';

import {getForecastListEndpoint, GetForecastListParams} from './getForecastListEndpoint';
import {GetForecastListResponse} from '../models/getForecastListResponse';

import {getForecastOverlayListEndpoint, GetForecastOverlayListParams} from './getForecastOverlayListEndpoint';
import {GetForecastOverlayListResponse} from '../models/getForecastOverlayListResponse';

import {downloadForecastOverlayEndpoint, DownloadForecastOverlayParams} from './downloadForecastOverlayEndpoint';

import {postForecastOverlayBulkEditEndpoint, PostForecastOverlayBulkEditParams} from './postForecastOverlayBulkEditEndpoint';
import {PostForecastOverlayBulkEditResponse} from '../models/postForecastOverlayBulkEditResponse';

import {postForecastApproveEndpoint, PostForecastApproveParams} from './postForecastApproveEndpoint';
import {postForecastCloneEndpoint, PostForecastCloneParams} from './postForecastCloneEndpoint';
import {postForecastDiscardEndpoint, PostForecastDiscardParams} from './postForecastDiscardEndpoint';
import {postForecastRegenerateEndpoint, PostForecastRegenerateParams} from './postForecastRegenerateEndpoint';
import {postForecastRestoreEndpoint, PostForecastRestoreParams} from './postForecastRestoreEndpoint';

/* eslint-disable @typescript-eslint/no-var-requires */
const apiGatewayClientFactory = require('aws-api-gateway-client').default;

const initForecastingApiGatewayClient = async (): Promise<ApiGatewayClient> => {
  const tokenPayload = await authTokenPayload();

  const forecastingApiConfig = config.Amplify.API.endpoints.find((entry) => entry.name === SystemAPIs.ForecastingApi.toString());
  const forecastingApiCredentials = tokenPayload?.backendApiCredentials as Credentials;

  return apiGatewayClientFactory.newClient({
    invokeUrl: forecastingApiConfig!.endpoint,
    accessKey: forecastingApiCredentials!.AccessKeyId,
    secretKey: forecastingApiCredentials!.SecretAccessKey,
    sessionToken: forecastingApiCredentials!.SessionToken,
    region: forecastingApiConfig!.region,
  });
};

type EndpointFn<T, U> = (arg1: ApiGatewayClient, arg2: T) => Promise<U | void>;
async function execAndHandleError<T, U>(endpoint: EndpointFn<T, U>, params: T): Promise<U | void> {
  try {
    const client = await initForecastingApiGatewayClient();
    return endpoint(client, params);
  } catch (error) {
    const errorCode = extractStatusCode(error as ApiGatewayErrorResponse);
    if (errorCode === 404) {
      return;
    }
    handleError(error as ApiGatewayErrorResponse);
  }
}

export const getForecast = async (params: GetForecastParams): Promise<ForecastRecordWithValues | void> => {
  return execAndHandleError(getForecastEndpoint, params);
};

export const getForecastMetadata = async (params: GetForecastMetadataParams): Promise<GetForecastMetadataResponse | void> => {
  return execAndHandleError(getForecastMetadataEndpoint, params);
};

export const getForecastMetric = async (params: GetForecastMetricParams): Promise<GetForecastMetricResponse | void> => {
  return execAndHandleError(getForecastMetricEndpoint, params);
};

export const getForecastGraph = async (params: GetForecastGraphParams): Promise<ForecastGraph | void> => {
  return execAndHandleError(getForecastGraphEndpoint, params);
};

export const getForecastList = async (params: GetForecastListParams): Promise<GetForecastListResponse | void> => {
  return execAndHandleError(getForecastListEndpoint, params);
};

export const getForecastOverlayList = async (params: GetForecastOverlayListParams): Promise<GetForecastOverlayListResponse | void> => {
  return execAndHandleError(getForecastOverlayListEndpoint, params);
};

export const downloadForecastOverlay = async (params: DownloadForecastOverlayParams): Promise<string | void> => {
  return execAndHandleError(downloadForecastOverlayEndpoint, params);
};

export const postForecastOverlayBulkEdit = async (params: PostForecastOverlayBulkEditParams): Promise<PostForecastOverlayBulkEditResponse | void> => {
  return execAndHandleError(postForecastOverlayBulkEditEndpoint, params);
};

export const postApproveForecast = async (params: PostForecastApproveParams): Promise<ForecastRecord | void> => {
  return execAndHandleError(postForecastApproveEndpoint, params);
};

export const postCloneForecast = async (params: PostForecastCloneParams): Promise<ForecastRecord | void> => {
  return execAndHandleError(postForecastCloneEndpoint, params);
};

export const postDiscardForecast = async (params: PostForecastDiscardParams): Promise<ForecastRecord | void> => {
  return execAndHandleError(postForecastDiscardEndpoint, params);
};

export const postRegenerateForecast = async (params: PostForecastRegenerateParams): Promise<ForecastRecord | void> => {
  return execAndHandleError(postForecastRegenerateEndpoint, params);
};

export const postRestoreForecast = async (params: PostForecastRestoreParams): Promise<ForecastRecord | void> => {
  return execAndHandleError(postForecastRestoreEndpoint, params);
};
