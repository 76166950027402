import React from 'react';
import Select, {SelectOption} from '@amzn/meridian/select';

const options = [
  {label: 'AFS', value: 'AFS'},
  {label: 'UFF', value: 'UFF'},
];

interface IProps {
  onSelect: (business: string) => void;
  value: string | undefined;
};

const BusinessSelector = ({onSelect, value}: IProps) => {
  return (
    <Select value={value} onChange={onSelect} width={300} label="Business" disabled={false} popoverMaxHeight={250}>
      {options.map((option, index) => (
        <SelectOption label={option.label} value={option.value} key={index} />
      ))}
    </Select>
  );
};

export default BusinessSelector;
