import React, {useCallback, useState} from 'react';

export interface BusinessRegionContextType extends BusinessRegionState {
  clearBusinessRegionSelection: () => void;
  setBusinessRegionSelection: (business: string, country: string, region: number) => void;
};

export interface BusinessRegionState {
  business: string | null;
  region: number | null;
  country: string | null;
  userSelection: string;
};

const buildUserSelection = (business: string | null, country: string | null) => {
  let userSelectionBuilder = '';
  if (business && business !== 'undefined') userSelectionBuilder += business;
  if (country && country !== 'undefined') userSelectionBuilder += '-' + country;

  return userSelectionBuilder;
};

const userStorage = window.localStorage;
const defaultState: BusinessRegionState = {
  business: userStorage.getItem('business'),
  region: Number(userStorage.getItem('region')),
  country: userStorage.getItem('country'),
  userSelection: buildUserSelection(userStorage.getItem('business'), userStorage.getItem('country')),
};

export const BusinessRegionContext = React.createContext<BusinessRegionContextType>({
  ...defaultState,
  clearBusinessRegionSelection: () => {},
  setBusinessRegionSelection: () => {},
});

const BusinessRegionProvider = ({children}: React.PropsWithChildren<Record<never, any>>) => {
  const [state, setState] = useState(defaultState);

  const setBusinessRegionSelection = (business: string, country: string, region: number): void => {
    setState({
      ...state,
      business,
      country,
      region,
      userSelection: buildUserSelection(business, country),
    });
  };
  const clearBusinessRegionSelection = useCallback(() => {
    setState({
      ...state,
      business: null,
      country: null,
      region: null,
      userSelection: '',
    });
  }, [state]);

  return (
    <BusinessRegionContext.Provider value={{
      ...state,
      clearBusinessRegionSelection,
      setBusinessRegionSelection,
    }} >
      {children}
    </BusinessRegionContext.Provider>
  );
};

export default BusinessRegionProvider;
