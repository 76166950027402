import {RawNodeDatum} from 'react-d3-tree/lib/types/common';
import {determineForecastState} from '../../../app/ForecastProvider';
import {ForecastGraph} from '../../../common/apis/models/getForecastGraphResponse';
import {ForecastRecordWithValues} from '../../../common/apis/models/getForecastResponse';
import {IN_PROGRESS} from '../../../common/constants/forecastStatus';
import {DISCARDED} from '../../../common/constants/forecastType';
import {convertTimestampTicks, getLocalizedDateTimeString} from '../../../common/utils/dates';
import {formatForecastName, formatForecastStatus, formatFriendlyId} from '../../../common/utils/string';

interface NodeData extends RawNodeDatum {
  parent: NodeData | null;
}

export interface NodeAttributes {
  forecastId: string;
  versionId: number;
  name: string;
  description: string;
  author: string;
  updated: string;
  currentUserCanRestore: boolean;
  isAncestor: boolean;
  isDiscarded: boolean;
  isProcessing: boolean;
  isSelected: boolean;
};

interface FormatGraphProps {
  forecastId: string;
  versionId: number;
  forecastGraph: ForecastGraph;
  currentUser: string;
  isAdministrator: boolean;
  isApprover: boolean;
}
export const formatGraphData = ({
  forecastId,
  versionId,
  currentUser,
  isAdministrator,
  isApprover,
  forecastGraph,
}: FormatGraphProps): RawNodeDatum => {
  const root = formatGraphNodeData(
    {forecastId, versionId, currentUser, isAdministrator, isApprover, parent: null},
    forecastGraph);
  let selectedNode = getSelectedNode(root)?.parent;
  while (selectedNode) {
    selectedNode.attributes!.isAncestor = true;
    selectedNode = selectedNode.parent;
  }
  return root;
}

const getSelectedNode = (node: NodeData): NodeData | null => {
  if (node.attributes?.isSelected) { return node; }
  if (!node.children) { return null; }
  for (let i = 0; i < node.children.length; i++) {
    const selected = getSelectedNode(node.children[i] as NodeData);
    if (selected) { return selected; }
  }
  return null;
};

const formatGraphNodeData = (
  {
    forecastId,
    versionId,
    currentUser,
    isAdministrator,
    isApprover,
    parent,
  }: {
    forecastId: string,
    versionId: number,
    currentUser: string,
    isAdministrator: boolean,
    isApprover: boolean,
    parent: NodeData | null,
  },
  forecastGraph: ForecastGraph,
): NodeData => {
  const forecast = forecastGraph.node;
  const forecastState = determineForecastState(forecastGraph.node as ForecastRecordWithValues, currentUser, isAdministrator, isApprover);
  const node = {
    name: formatFriendlyId(forecast),
    attributes: {
      forecastId: forecast.forecastId,
      versionId: forecast.versionId,
      name: formatForecastName(forecast),
      description: forecast.description,
      status: formatForecastStatus(forecast.status, forecast.type),
      author: forecast.updatedBy,
      updated: getLocalizedDateTimeString(convertTimestampTicks(forecast.updatedAt)),
      currentUserCanRestore: forecastState.canOverlay,
      isAncestor: false,
      isDiscarded: forecast.type === DISCARDED,
      isProcessing: forecast.status === IN_PROGRESS,
      isSelected: forecast.forecastId === forecastId && forecast.versionId === versionId,
    },
    parent: parent,
    children: [],
  } as NodeData;
  node.children = forecastGraph.children.map(formatGraphNodeData.bind(null, {
    forecastId, versionId, currentUser, isAdministrator, isApprover, parent: node
  }));
  return node;
};