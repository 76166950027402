import React from 'react';
import AuthenticationProvider from './AuthenticationProvider';
import AuthorizationProvider from './AuthorizationProvider';
import BusinessRegionProvider from './BusinessRegionProvider';
import ForecastProvider from './ForecastProvider';
import ForecastVersionProvider from './ForecastVersionProvider';
import ThemeProvider from './ThemeProvider';

const CompositeProvider = ({children}: React.PropsWithChildren<Record<never, any>>) =>
  (
    <AuthenticationProvider>
      <ThemeProvider>
        <BusinessRegionProvider>
          <AuthorizationProvider>
            <ForecastVersionProvider>
              <ForecastProvider>
                {children}
              </ForecastProvider>
            </ForecastVersionProvider>
          </AuthorizationProvider>
        </BusinessRegionProvider>
      </ThemeProvider>
    </AuthenticationProvider>
  );

export default CompositeProvider;
