import React, {useEffect, useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import AppLayout from '@amzn/meridian/app-layout';
import Theme from "@amzn/meridian/theme"
import {useMediaQuery} from 'react-responsive';
import {ForecastRecord} from '../common/apis/models/forecast';
import {WIDE_NAV_QUERY} from '../common/utils/layout';
import {formatFriendlyId, formatForecastName} from '../common/utils/string';
import {
  useAuthorizationContext,
  useBusinessRegionContext,
  useForecastContext,
  useForecastVersionContext,
  useThemeContext,
} from './hooks';
import AdminPage from '../features/admin-page/AdminPage';
import DashboardPage from '../features/dashboard-page/DashboardPage';
import HelpPage from '../features/help-page/HelpPage';
import NavBar from '../features/navigation-bar/NavBar';
import NavBarSide from '../features/navigation-bar-side/NavBarSide';
import SelectBusinessPage from '../features/select-business-page/SelectBusinessPage';
import ErrorPage from '../features/error-page/ErrorPage';
import UnauthorizedPage from '../features/unauthorized-page/UnauthorizedPage';
import HistoryPage from '../features/history-page/HistoryPage';
import ForecastSummaryPage from '../features/forecast-summary-page/ForecastSummaryPage';
import MetadataPage from '../features/metadata-page/MetadataPage';
import OverlayPage from '../features/overlay-page/OverlayPage';
import {OverlayMetricLevel} from '../features/overlay-page/models';
import getSideNavigationDefinition from '../features/navigation-bar-side/getSideNavigationDefinition';
import {
  metricNames,
  PRICE_INDEX,
  DISCOUNTS,
  COVID_MOBILITY,
  UFT,
  OOC_SD,
  OOC_ND,
} from '../common/constants/forecastMetric';

const formatTitle = (forecast: ForecastRecord | null) => {
  if (!forecast) { return ''; }
  return `${formatFriendlyId(forecast)} - ${formatForecastName(forecast)} - ${forecast.description} `;
}

const ToplineApp = () => {
  const {userSelection} = useBusinessRegionContext();
  const {forecastId, versionId} = useForecastVersionContext();
  const {theme} = useThemeContext();
  const {isAuthorized, permissionsSet} = useAuthorizationContext();
  const forecastProps = useForecastContext();
  const {
    state: {forecast, forecastState},
    addForecastOverlayVersion,
    clearUnprocessedOverlayMetrics,
  } = forecastProps;
  const [pageTitle, setPageTitle] = useState<string | void>();
  const isWidescreen = useMediaQuery(WIDE_NAV_QUERY);
  const RedirectToSelection = <Redirect to="/select-business" />;
  const RedirectToUnauthorized = <Redirect to="/unauthorized" />;
  const redirect = (hasPermission: boolean, page: JSX.Element) => (isAuthorized && userSelection ? (hasPermission ? page: RedirectToUnauthorized) : RedirectToSelection);

  const formattedForecastTitle = forecastId ? formatTitle(forecast) : '';
  const formattedTitle = pageTitle ? `${formattedForecastTitle}${formattedForecastTitle ? ' - ' : ''}${pageTitle}` : formattedForecastTitle;

  useEffect(() => {
    document.title = formattedTitle ?  `F3 Topline - ${formattedTitle}` : 'F3 Topline';
  }, [formattedTitle]);

  const getPageProps = (pageKey: string) => ({
    ...(forecastProps.state),
    key: `${userSelection}:${forecast?.forecastId || ''}:${forecast?.versionId || ''}:${pageKey}`,
    setPageTitle,
  });
  const getOverlayPageProps = (metricId: string, metricLevel: OverlayMetricLevel, uploadEnabled: boolean) => ({
    ...getPageProps(`overlay-${metricId.toLowerCase()}`),
    metricId,
    metricLevel,
    metricName: metricNames[metricId],
    uploadEnabled,
    addForecastOverlayVersion,
  });
  const forecastPath = forecastId ?
    `/forecasts/${forecastId}/versions/${versionId}` : null;

  return (
    <BrowserRouter>
      <Theme tokens={theme.MeridianTheme}>
        <AppLayout headerComponent={NavBar} sidebarComponent={NavBarSide} backgroundColor={theme.Background}>
          <NavBar pageTitle={formattedTitle} permissionSet={permissionsSet} />
          {
            forecastPath && <NavBarSide sections={
              getSideNavigationDefinition(
                forecast,
                forecastPath,
                forecastState,
                permissionsSet,
                isWidescreen,
                clearUnprocessedOverlayMetrics
              )}
            />
          }
          <Switch>
            <Route path="/error">
              <ErrorPage />
            </Route>
            <Route path="/unauthorized" exact={true}>
              <UnauthorizedPage />
            </Route>
            <Route path="/select-business" exact={true}>
              <SelectBusinessPage />
            </Route>
            <Route path="/help" exact={true}>
              <HelpPage />
            </Route>
            <Route path="/admin" exact={true}>
              {redirect(permissionsSet.has('canViewAdmin'), <AdminPage />)}
            </Route>
            <Route path="/" exact={true}>
              {redirect(permissionsSet.has('canViewDashboard'), <DashboardPage {...getPageProps('dashboard')} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/history" exact={true}>
              {redirect(permissionsSet.has('canViewHistory'), <HistoryPage {...getPageProps('history')} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/summary" exact={true}>
              {redirect(permissionsSet.has('canViewSummary'), <ForecastSummaryPage {...getPageProps('summary')} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/metadata" exact={true}>
              {redirect(permissionsSet.has('canViewSummary'), <MetadataPage {...getPageProps('metadata')} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/price-index" exact={true}>
              {redirect(permissionsSet.has('canOverlayForecast'), <OverlayPage {...getOverlayPageProps(PRICE_INDEX, OverlayMetricLevel.FC_LEVEL, true)} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/discounts" exact={true}>
              {redirect(permissionsSet.has('canOverlayForecast'), <OverlayPage {...getOverlayPageProps(DISCOUNTS, OverlayMetricLevel.FC_LEVEL, true)} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/covid-mobility" exact={true}>
              {redirect(permissionsSet.has('canOverlayForecast'), <OverlayPage {...getOverlayPageProps(COVID_MOBILITY, OverlayMetricLevel.FC_LEVEL, false)} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/uft" exact={true}>
              {redirect(permissionsSet.has('canOverlayForecast'), <OverlayPage {...getOverlayPageProps(UFT, OverlayMetricLevel.NETWORK_LEVEL, true)} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/oocsd" exact={true}>
              {redirect(permissionsSet.has('canOverlayForecast'), <OverlayPage {...getOverlayPageProps(OOC_SD, OverlayMetricLevel.NETWORK_LEVEL, true)} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/oocnd" exact={true}>
              {redirect(permissionsSet.has('canOverlayForecast'), <OverlayPage {...getOverlayPageProps(OOC_ND, OverlayMetricLevel.NETWORK_LEVEL, true)} />)}
            </Route>
          </Switch>
        </AppLayout>
      </Theme>
    </BrowserRouter>
  );
};

export default ToplineApp;
